import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const ButtonStyled = styled.button`
  align-items: center;
  border-radius: 1px;
  box-shadow: none;
  display: inline-flex;
  font-size: 21px;
  font-weight: 500;
  justify-content: center;
  min-height: 60px;
  min-width: 250px;
  outline: none;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.4s;
  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
  }
`

const ButtonStyledMonochrome = styled(ButtonStyled)`
  background: transparent;
  border: ${({ theme }) => `1px solid ${theme.colors.light}`};
  color: ${({ theme }) => theme.colors.light};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.light};
    color: ${({ theme }) => theme.colors.dark};
  }
`

const ButtonStyledRed = styled(ButtonStyled)`
  background: transparent;
  border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  color: ${({ theme }) => theme.colors.primary};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.light};
  }
`

export const ReturnHomeButton = ({ monochrome, label, style }) => {
  const Button = monochrome ? ButtonStyledMonochrome : ButtonStyledRed

  return (
    <>
      <Link to='/'>
        <Button style={style}>{label || 'return home'}</Button>
      </Link>
    </>
  )
}

export default ReturnHomeButton
