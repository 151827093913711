import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import PageWrapper from '../components/PageWrapper'
import { Title, Section, Box } from '../components/Core'
import { ReturnHomeButton } from '../components/ReturnHomeButton'

import backgroundImage from '../assets/image/jpeg/cellist.jpg'

const BoxStyled = styled(Box)`
  background-image: url(${backgroundImage});
  min-height: 100vh;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Footer = styled(Box)`
  justify-content: flex-end;
`

const ComingSoon = () => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <BoxStyled>
          <Box></Box>
          <Box>
            <Container className='text-center'>
              <Row className='justify-content-center'>
                <Col lg='10' xl='8'>
                  <Section>
                    <div>
                      <Title color='light'>
                        Feeling homesick for a place you're not even sure
                        exists?
                      </Title>
                      <ReturnHomeButton
                        monochrome
                        label='retvrn'
                        style={{ letterSpacing: '5pt' }}
                      />
                    </div>
                  </Section>
                </Col>
              </Row>
            </Container>
          </Box>

          <Footer pb='70px'>
            <Container></Container>
          </Footer>
        </BoxStyled>
      </PageWrapper>
    </>
  )
}
export default ComingSoon
